<template>
  <div class="serviceStation flex pt20px pd1h">
    <div class="">
      <div class="part">
        <itemBg width="49" height="44" title="当月销量 " />
        <div class="part_con">
          <e-charts id="order" width="49vw" height="44vh" :options="lt" />
        </div>
      </div>
      <div class="part mt2h">
        <itemBg width="49" height="44" title="当月销量占比" />
        <div class="part_con flex">
          <e-charts id="sevev_order_left" width="49vw" height="44vh" :options="lb" />
        </div>
      </div>
    </div>
    <div class="">
      <div class="part">
        <itemBg width="49" height="44" title="累计销量" />
        <div class="part_con"><e-charts id="add" width="49vw" height="44vh" :options="rt" /></div>
      </div>
      <div class="part mt2h">
        <itemBg width="49" height="44" title="站点销售月增量 " />
        <div class="part_con">
          <e-charts id="sevenDayTownOrder" width="49vw" height="44vh" :options="rb" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { BAR_OPTINO, PIE_OPTINO, LINE_OPTINO } from '@/utils/eChartsSetOption'
import routeChange from '@/mixins/routeChange'
import itemBg from '@/components/itemBg'
import { SiteSales } from '@/api/siteSales'

export default {
  name: '',
  components: { itemBg },
  mixins: [routeChange],
  data() {
    return {
      lt: JSON.parse(JSON.stringify(BAR_OPTINO)),
      lb: JSON.parse(JSON.stringify(PIE_OPTINO)),
      rt: JSON.parse(JSON.stringify(BAR_OPTINO)),
      rb: JSON.parse(JSON.stringify(LINE_OPTINO))
    }
  },
  computed: {},
  created() {},
  mounted() {
    this.getSiteSales()
  },
  methods: {
    getSiteSales() {
      SiteSales().then((res) => {
        // console.log(res)
        this.lt.xAxis.data = res.currentMonthSalesVolume[0].siteName
        this.lt.series[0].data = res.currentMonthSalesVolume[0].amountSum
        this.lt.grid.left = '8%'
        this.lt.yAxis.splitLine.lineStyle.color = 'rgba(87,206,234,0.1)'
        this.lt.xAxis.axisLabel.textStyle.color = '#fff'

        this.lb.series[0].data = res.currentMonthSalesVolumeProportion
        this.rt.xAxis.data = res.salesVolumeTotal[0].siteName
        this.rt.series[0].data = res.salesVolumeTotal[0].amountSum
        this.rt.grid.left = '8%'
        this.rt.yAxis.splitLine.lineStyle.color = 'rgba(87,206,234,0.1)'
        this.rt.xAxis.axisLabel.textStyle.color = '#fff'

        this.rb.yAxis.splitLine.lineStyle.color = 'rgba(87,206,234,0.1)'
        this.rb.xAxis.axisLabel.textStyle.color = '#fff'
        this.rb.xAxis.data = res.monthlySalesIncrement.x
        this.rb.legend.data = Object.keys(res.monthlySalesIncrement.data)
        Object.entries(res.monthlySalesIncrement.data).forEach((e) => {
          this.rb.series.push({
            name: e[0],
            data: e[1],
            type: 'line',
            smooth: true
            // stack: '总量'
          })
        })
        this.rb.grid.left = 90
      })
    }
  }
}
</script>
<style lang="scss"></style>
