import request from '@/utils/request'

/**
乡镇站点销量统计
 */
export function SiteSales() {
  return request({
    url: '/SiteSales/query',
    method: 'get'
  })
}
